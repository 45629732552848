<template>
  <div class="standard-page">
    <!-- The file input for influencers list -->
    <input
      type="file"
      name="influencer-outreach-influencers-file"
      accept=".csv"
      class="d-none"
      ref="influencersFileUploadInput"
      @change="handleInfluencersFileChange"
    />

    <!-- If we don't have the InfluencerOutreach data just yet -->
    <template v-if="!data">
      <div
        class="mx-auto"
        style="max-width: 360px"
      >
        <lottie-animation
          loop
          key="antenna"
          file="loading-files-StOdYbIRa1.json"
        />
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>
      <!-- If we don't have any influencer in this campaign -->
      <template v-if="data.influencers.length === 0">
        <!-- The header buttons -->
        <div class="d-flex justify-space-between">
          <!-- Show the go back button -->
          <v-btn
            text
            color="primary"
            @click="$router.push({ name: 'InfluencerOutreachIndex' })"
          >
            <v-icon left>
              arrow_back
            </v-icon>

            Campaigns
          </v-btn>
        </div>

        <!-- Show an animation first -->
        <div class="mx-auto" style="max-width: 360px">
          <lottie-animation
            loop
            key="shaking-box"
            file="106964-shake-a-empty-box.json"
          />
        </div>

        <!-- Show the text -->
        <div class="text-center text-h6 font-weight-bold">
          You don't have any influencer in this Campaign
        </div>

        <div class="text-center mt-2">
          Start by adding influencers below
        </div>

        <!-- Show the button -->
        <div class="text-center">
          <v-btn
            depressed
            class="mt-6"
            color="primary"
            @click="shouldShowAddDialog = true"
          >
            <v-icon left>
              add
            </v-icon>

            Add Influencers
          </v-btn>
        </div>
      </template>

      <!-- Otherwise, show the router-view child component -->
      <div
        v-else
        class="influencer-outreach-child-view"
      >
        <router-view
          :data="data"
          :key="reloadKey"
          @refreshData="fetchDetails"
          @triggerAddInfluencer="shouldShowAddDialog = true"
        />
      </div>
    </template>

    <!-- Show the dialog box to add influencers -->
    <v-dialog
      v-model="shouldShowAddDialog"
      max-width="500"
      :persistent="shouldFormBeDisabled"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between width-100">
            <!-- Show the text -->
            <div>
              Add Influencers
            </div>

            <!-- Show the button to upload in bulk -->
            <v-btn
              depressed
              color="primary"
              :disabled="shouldFormBeDisabled"
              :loading="isUploading"
              @click="$refs.influencersFileUploadInput && $refs.influencersFileUploadInput.click()"
            >
              <v-icon left>
                file_upload
              </v-icon>

              Upload CSV
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="accent pt-6">
          <div class="d-flex">
            <!-- show the input for searching terms -->
            <div class="search-input flex-grow-1">
              <profile-selector
                v-model="formData.username"
                @change="handleProfileChange"
                :platform="formData.platform"
                :use-combobox="true"
                :hide-no-data="true"
                type="search"
                label="Search Profile"
                :disabled="shouldFormBeDisabled"
                dense
                flat
                solo
              />
            </div>

            <!-- the select options for different platforms -->
            <platform-selector
              :value="formData.platform"
              class="platform-selector"
              :disabled="shouldFormBeDisabled"
              @input="handlePlatformChange"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- Show the cancel button -->
          <v-btn
            text
            :disabled="shouldFormBeDisabled"
            @click="shouldShowAddDialog = false"
          >
            Cancel
          </v-btn>

          <!-- Show the submit form button -->
          <v-btn
            depressed
            color="primary"
            @click="doSubmitForm"
            :disabled="shouldFormBeDisabled"
            :loading="isMakingRequest"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node package
const CSV = require("csv")

// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Import helper functions
import platformRegex from "@/helpers/platformRegex"
import messageEvents from "@/helpers/messageEvents"
import { required, maxLength, minLength } from "vuelidate/lib/validators"

// Define the generator function for formData
const generateFormData = () => ({
  platform: "instagram",
  username: ""
})

// Define the key for handling subscriptions
const subscriptionId = Symbol("InfluencerOutreach/View")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachView",

  // Register children components
  components: {
    LottieAnimation,
    ProfileSelector,
    PlatformSelector
  },

  // Define local data variables
  data: () => ({
    // Whether or not to show the add influencer dialog
    shouldShowAddDialog: false,

    // If the form is being submitted
    isMakingRequest: false,

    // If it's uploading the spreadsheet file
    isUploading: false,

    // If the data is being loaded
    isLoading: false,

    // The reload key to make sure the UI is always up-to-date
    reloadKey: Symbol(),

    // Store the form data for the request
    formData: generateFormData()
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the model data from Vuex store
     *
     * @returns {Object}
     */
    data() {
      return this.$store.getters["influencerOutreach/detailByUuid"](this.$route.params.uuid)
    },

    /**
     * If the code is making a network request, disable the buttons and inputs
     *
     * @returns {Boolean}
     */
    shouldFormBeDisabled() {
      return this.isMakingRequest || this.isUploading
    }
  },

  // Define vuelidate validations object
  validations: {
    formData: {
      username: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200)
      }
    }
  },

  /**
   * Handle the form submit event to add the influencer to the model
   *
   * @returns {void}
   */
  methods: {
    /**
     * When called, fetch and update the campaign data in Vuex store
     *
     * @returns {void}
     */
    async fetchDetails() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Wait for the load to be completed
      await this.$store.dispatch("influencerOutreach/fetchDetails", this.$route.params.uuid)

      // Hide the loaders
      this.$store.dispatch("loaders/remove", loaderId)
      this.isLoading = false

      // Also, reload the children view
      this.reloadKey = Symbol()
    },

    /**
     * Every time the user selects or submits the user input form, call this function
     *
     * @param {Object} selectedUser
     * @return {void}
     */
    handleProfileChange(selectedUser) {
      // If the search query is empty
      if (!this.formData.username) return false

      // Check if the value is a URL
      if (isURL(selectedUser)) {
        // Try to get the username from it
        const match = selectedUser.match(platformRegex[this.selectedPlatform])

        // If the regex worked
        if (match !== null && match[1]) {
          // Also update the value of the form input
          this.formData.username = match[1]

          // return to make sure the function ends here
          return true
        }
      }
    },

    /**
     * Whenever the user switches the platform selected, reset the form
     *
     * @param {String} value
     * @returns {void}
     */
     handlePlatformChange(value) {
      // Reset the username value
      this.formData.username = null

      // Update the platform value
      this.formData.platform = value
    },

    /**
     * Try to validate and then submit the form
     *
     * @returns {void}
     */
    async doSubmitForm() {
      // Run the validations
      await this.$v.formData.$touch()

      // If there's any error
      if (this.$v.formData.$anyError) {
        // Stop the execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        await axios({
          url: `/api/influencer-outreach/${this.data.model.id}/influencers`,
          method: "POST",
          data: {
            platform: this.formData.platform,
            username: typeof this.formData.username === "object" ? this.formData.username.nameToShow : this.formData.username
          }
        })

        // If succeeded, show a toast message
        this.$store.dispatch("toasts/add", { text: "Influencer added to the campaign" })

        // Reset the form
        this.formData = generateFormData()

        // Fetch the campaign data again
        this.fetchDetails()

        // Also hide the dialog
        this.shouldShowAddDialog = false
      }
      // Catch the error
      catch (error) {
        // Log using the helper function
        logger({ type: "Network Error", error })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // At last, hide the loaders
      finally {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Handle the file input change for bulk influencers upload
     *
     * @returns {void}
     */
    handleInfluencersFileChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })

        // End the execution
        return
      }

      // Get the file object
      const file = event.target.files[0]

      // Validate the mime type of this file is CSV
      if (file.type !== "text/csv") {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "File is not a CSV" })

        // End the execution
        return
      }

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isUploading = true

      // Define a function to hide the loaders
      const doHideLoaders = () => {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isUploading = false
      }

      // Otherwise, try to read the file
      const reader = new FileReader()

      // Add a callback function on it
      reader.addEventListener("load", () => {
        // Get the text content from it
        const text = reader.result

        // Try parsing the text as a CSV document
        CSV.parse(text, async (error, result) => {
          // If there's any error
          if (error) {
            // Log the error
            logger({ type: "CSV Parse Error", error })

            // Show an error message
            this.$store.dispatch("toasts/add", { text: "File is not a valid CSV document" })

            // Also hide the loaders
            doHideLoaders()

            // End the execution
            return
          }
          // Otherwise
          else {
            // Get all the URLs from the CSV file
            const URLs = {
              instagram: [],
              youtube: [],
              tiktok: []
            }

            // Keep a count of URLs
            let counter = 0

            // Go through each row
            for (const row of result) {
              // Go through each column
              for (const column of row) {
                // Get the value
                const value = column.trim()

                // Go through each platform
                for (const platform of Object.keys(platformRegex)) {
                  // Check if the value is valid
                  const match = value.match(platformRegex[platform])

                  // If match found
                  if (match !== null && match[1]) {
                    // Add it to the object
                    URLs[platform].push( match[1] )

                    // Increment the counter
                    counter++
                  }
                }
              }
            }

            // If there are no profile links
            if (counter === 0) {
              // Show an error message
              this.$store.dispatch("toasts/add", { text: "No valid URL found in this CSV file" })

              // Also hide the loaders
              doHideLoaders()

              // End the execution
              return
            }
            // Otherwise, if there are more than 20,000 URLs
            else if (counter > 20_000) {
              // Show an error message
              this.$store.dispatch("toasts/add", { text: "You cannot add more than 20,000 profiles at once" })

              // Also hide the loaders
              doHideLoaders()

              // End the execution
              return
            }
            // Finally
            else {
              // Try to make the network request
              try {
                await axios({
                  url: `/api/influencer-outreach/${this.data.model.id}/influencers/bulk`,
                  method: "POST",
                  data: URLs
                })

                // If succeeded, show a toast message
                this.$store.dispatch("toasts/add", { text: "Influencers are being added to campaign, the list will update shortly!" })

                // Reset the form
                this.$refs.influencersFileUploadInput.value = null

                // Also hide the dialog
                this.shouldShowAddDialog = false
              }
              // Catch the error
              catch (error) {
                // Log using the helper function
                logger({ type: "Network Error", error })

                // Show an error message
                this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "Could not upload influencers, please try again!" })
              }
              // At last, hide the loaders
              finally {
                doHideLoaders()
              }
            }
          }
        })
      })

      // Read the file as a text
      reader.readAsText(file)
    },

    /**
     * When triggered, refresh the data to fetch updated list of influencers
     *
     * @returns {void}
     */
    handleNotificationRefreshAction() {
      // Dispatch a request to create a network request
      this.fetchDetails()
    },

    /**
     * Handle the message event for campaign views
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the import just started
      if (event.key === "influencers-import-started") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "Your influencers are being added to the campaign!",
          type: "info",
          icon: "lightbulb",
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }

      // If a chunk of import has been completed
      if (event.key === "influencers-import-completed") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "A bunch of your influencers have been added, check progress!",
          type: "success",
          icon: "check_circle",
          button: {
            text: "Refresh",
            action: this.handleNotificationRefreshAction
          },
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }
    }
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // Dispatch a request to create a network request
    this.fetchDetails()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-outreach",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-outreach",
      callback: this.handleMessageEvent
    })
  },

  /**
   * Right before the component is about to be un-rendered
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>